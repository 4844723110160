<!-- 查询缴费 -->
<template>
  <div id="searchPay">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          :title="$t('searchPay.title')"
          :left-text="$t('main.return')"
          left-arrow
          style="background-color: rgba(255,255,255,0);"
          @click-left="onClickLeft"
        />
      </div>
    </div>
    <div id="listLoading">
      <van-loading
        v-if="loading"
        type="spinner"
      />
    </div>
    <div class="outboxstyle">
      <div class="boxstyle">
        <van-row>
          <van-col :span="24">
            <div class="clearfix">
              <!-- <img :src="iconList[2]" alt="" class="icon img left"> -->
            </div>
            <div class="picker-img">
              <van-field
                v-model="queryForm.objectName"
                name="关键字"
                autocapitalize="off"
                autocorrect="off"
                autocomplete="off"
                left-icon="searchPng"
                :placeholder="$t('searchPay.input')"
                class="picker"
                clearable
                @input="search"
              />
              <img src="../../assets/icon/search.png" class="body-img">
            </div>
          </van-col>
        </van-row>
        <!-- <p style="line-height:50px">已选<span style="color:#3BA4F2">&nbsp;&nbsp;{{ count }}&nbsp;&nbsp;</span>项,合计:<span style="float:right;color:red">¥&nbsp;{{ total }}</span></p> -->
      </div>
      <van-list
        v-model="isUpLoading"
        class="list"
        :immediate-check="false"
        finished-text="没有更多了"
        :finished="upFinished"
        :offset="offset"
        @load="onLoad"
      >
        <div v-for="(item, index) in list" :key="index" @click="nextStep(item)">
          <div class="title-part">
            <span class="blue-line" />
            <div v-html="item.colorword" />
          </div>
          <div class="search-part">
            <div class="part-left">
              <img :src="item.projectImgUrl">
              <div>
                <span class="name">{{ item.projectName }}</span>
                <span class="batch">{{ item.batchno }}</span>
              </div>
            </div>
            <div class="part-right">
              <span>￥{{ fenToYuan(item.unpaidamt) }}</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
      </van-list>
      <van-empty
        v-show="list.length===0&&queryForm.objectName===''"
        image="search"
        class="custom-image"
        description="请输入关键字查询"
      />
      <van-empty
        v-show="list.length===0&&queryForm.objectName!==''"
        description="搜索结果为空"
      />
    </div>
    <van-dialog v-model="show" :title="$t('searchPay.fix')" show-cancel-button :before-close="chargeBtn">
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field v-model="money" style="width: 90%;margin: 20px;border: 1px solid #eee;" type="digit" />
    </van-dialog>
    <!-- 回到顶部按钮 -->
    <v-top />
  </div>
</template>

<script>
// import { pageListReiForm } from '@/api/reiform'
//
// queryDetail createQueryPaymentOrder
import { getList } from '@/api/queryPayment/queryPayment'
import { fenToYuan } from '@/utils/commonUtils'
// import moment from 'moment/moment'
import { Dialog } from 'vant'
import { Toast, Empty } from 'vant'
export default {
  name: 'Project',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Empty.name]: Empty
  },
  data() {
    return {
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      chooseItem: {},
      activeIndex: '',
      textForm: {
        projectName: '',
        payamtStr: 0,
        paidAmtStr: 0,
        rebateAmountStr: 0,
        unPaidAmtStr: 0,
        actualPayAmountStr: 0,
        nameListId: '',
        projectId: ''
      },
      queryForm: {
        objectName: '',
        pageSize: 10,
        pageCurrent: 1,
        schoolCode: window.localStorage.getItem('schoolcode')
      },
      diyFormList: [],
      value: '',
      showPicker: false,
      checked: false,
      imgList: [
        require('../../assets/newImages/icon4.png'),
        require('../../assets/newImages/icon6.png'),
        require('../../assets/newImages/orderList4.png')

      ],
      imageL: [
        require('../../assets/newImages/home_icon6.png'),
        require('../../assets/newImages/right.png'),
        require('../../assets/newImages/rightbefore.png')
      ],
      list: [],
      total: 0,
      show: false,
      money: '',
      moneyindex: '',
      count: 0,
      nextLoading: false,
      sectionAmount: 0,
      allAmount: 0,
      loading: false,
      id: ''
    }
  },
  watch: {
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  created() {
  },
  mounted() {
  },
  methods: {
    // list到底
    onLoad() {
      console.log('出发')
      console.log('this.queryForm.objectName', this.queryForm.objectName)
      if (this.queryForm.objectName !== '') {
        console.log('出发2')
        this.loading = true
        setTimeout(() => {
          this.queryForm.pageCurrent = this.queryForm.pageCurrent + 1
          getList(
            this.queryForm
          ).then(
            response => {
              if (response.data.records.length === 0) {
                this.upFinished = true
                this.loading = false
                this.isUpLoading = false
                // Toast.fail('没有更多了')
                // this.conheight.background = '#EFF6FF'
                return false
              } else {
                const newlist = response.data.records
                this.newlist.forEach(item => {
                  this.$set(item, 'fullPay', true)
                })
                this.list = this.list.concat(newlist)
                this.isUpLoading = false
                this.loading = false
                Toast.success(this.$t('msg.loadSuccess')) // 弹出
              }
            }
          ).catch(err => {
            Toast.fail(err.message)
            this.isUpLoading = false
            this.loading = false
          })
        }, 500)
      } else {
        this.isUpLoading = false
        this.loading = false
        return
      }
    },
    strChange(search, key) {
      var contents = key
      var value = search
      var values = contents.split(value)
      return values.join('<span style="color:#2F9FF2;">' + value + '</span>')
    },
    search() {
      if (this.queryForm.objectName === '') {
        Toast.fail(this.$t('msg.keyEmpty'))
      } else {
        this.loading = true
        this.queryForm.pageCurrent = 1
        getList(this.queryForm).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.list.forEach(item => {
            this.$set(item, 'fullPay', true)
            this.$set(item, 'colorword', this.strChange(this.queryForm.objectName, item.objectName))
          })
          this.loading = false
          Toast.success(this.$t('msg.loadSuccess'))
        })
      }
    },
    // 选择
    choose(obj, index) {
      this.chooseItem = obj
      this.activeIndex = index
      obj.show = !obj.show
      this.id = obj.id
    },
    onInput(obj, index) {
      console.log(obj, index)
      // if (obj.payLimit === '-1' || obj.payLimit === '0' || obj.payLimit === -1 || obj.payLimit === 0 || obj.payLimit === null) {
      //   obj.fullPay = true
      //   Toast.fail('该缴费项目不允许部分缴费')
      //   return
      // }
      // 阻止事件穿透
      event.stopPropagation()
      this.moneyindex = index
      if (obj.fullPay === false) {
        this.show = true
        // 获取当前数据的未缴金额
        this.money = this.fenToYuan(obj.unpaidamt)
        // 获取当前数据的index
        // this.calculationData()
      } else {
        obj.fullPay === true
        // this.$set(this.list[this.moneyindex], 'fullPay', true)
        console.log(obj.fullPay)
        obj.payNowStr = obj.unpaidamt
        // this.calculationData()
      }
    },
    chargeBtn(action, done) {
      // 确认or取消
      if (action === 'cancel') { // 取消按钮
        this.list[this.moneyindex].fullPay = true
        // this.calculationData()
        done()
      } else if (action === 'confirm') { // 确定按钮
        if (this.money * 100 >= this.list[this.moneyindex].unpaidamt) {
          console.log('money', this.money)
          console.log('this.list[this.moneyindex].unpaidamt', fenToYuan(this.list[this.moneyindex].unpaidamt))
          Toast.fail(this.$t('msg.amountOver'))
          done(false)
          return false
        }
        // 向后端传值并关闭dialog弹出框
        this.list[this.moneyindex].payNowStr = this.money
        // this.calculationData()
        this.$set(this.list[this.moneyindex], 'fullPay', false)
        // this.list[this.moneyindex].fullPay = false
        this.show = false
        done()
      }
    },
    nextStep(item) {
      // console.log(item)
      this.$router.push({ path: 'searchPayDetail', query: {
        actualPayAmountStr: (item.unpaidamt) / 100 + '',
        id: item.id,
        projectId: item.projectId,
        batchno: item.batchno }
      })
    },
    checkRules(payList) {
      var errorFlag = true
      var list = payList
      if (list.length < 1) {
        Toast.fail('请选择待缴费记录')
        return false
      }
      // 如果部分缴费 判断是否输入金额为空
      list.forEach(item => {
        if (item.fullPay === false) {
          if (item.payNowStr === null || item.payNowStr === '' || item.payNowStr === undefined) {
            errorFlag = false
          }
        }
      })
      if (errorFlag === false) {
        Toast.fail('部分缴费不能为0或空')
        return false
      }
      return true
    },
    // 返回
    onClickLeft() {
      // this.$router.push({ path: '/todo' })
      this.$router.push({ path: '/home' })
    },
    // 金钱转换
    fenToYuan(val) {
      return fenToYuan(val)
    },
    // 计算选中的数据
    calculationData() {
      this.total = 0
      this.count = 0
      this.allAmount = 0
      this.sectionAmount = 0
      for (let i = 0; i < this.list.length; i++) {
        const allPay = []
        const sectionPay = []
        if (this.list[i].show === true) {
          if (this.list[i].fullPay === false) {
            sectionPay.push(this.list[i])
            for (let j = 0; j < sectionPay.length; j++) {
              this.sectionAmount += Number(sectionPay[j].payNowStr)
            }
          } else {
            allPay.push(this.list[i])
            for (let k = 0; k < allPay.length; k++) {
              this.allAmount += Number(allPay[k].payNowStr)
            }
          }
          this.count += 1
        }
      }
    //   this.allAmount = fenToYuan(this.allAmount)
    //   this.total = Number(this.sectionAmount) + Number(this.allAmount)
    }
  }
}
</script>
<style scoped lang="scss">
#searchPay   {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 86px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .van-switch {
    font-size: 24px;
    height: 22px;
    line-height: 24px;
  }
  .van-cell__value {
    height: 24px;
  }
}
.outboxstyle {
  // padding: 0px 10px 10px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.boxstyle {
  font-size:16px;
  border-radius:10px 10px 0px 0px;
  margin:0px 12px
}
.buttontext{
  width:90px;
  border-radius:5px
}
.bottom{
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
.bottombutton{
  margin: 10px 20px;
  width:90%;
  height: 40px;
}
.van-cell__value {
  span{
    color: #333333;
  }
}
#totalpay {
  .van-cell__value {
  span{
    color: #3BA4F2;
  }
}
}
.picker{
  border-radius: 4px;
  margin-top: 10px;
}
.picker-img{
  position: relative
}
.body-img{
  width: 16px;
  position: absolute;
  top: 12px;
  left: 9px;
}
.group {
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  margin: 20px 20px;
  border:1px solid #3BA4F2
}
.group2 {
  padding: 0px 20px 20px 20px;
  border-radius: 10px;
  margin: 20px 20px;
  border:1px solid #eeeeee
}
.line{
  width:40px;
  position:absolute;
  right:0;
  top:-0;
  z-Index:2;
}
.total{
  font-size:18px;
  line-height:70px;
  font-weight:700;
  display: flex;
  align-items: center;
  img{
    align-items: center;
    margin:0 20px 0 10px;
    width:40px;
  }
}
.search-part{
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.04);
  border-radius: 8px;
  margin: 10px 12px;
  padding: 0 12px 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .part-left{
    display: flex;
    img{
      width: 40px;
      height: 40px;
      border: 1px solid #D0D0D0;
      border-radius: 6px;
      margin-right: 8px;
    }
    div{
      display: flex;
      flex-direction: column;
      height: 40px;
      justify-content: center;
      .name{
        color: #2E2E2E;
        font-size: 16px;
        margin-bottom: 2px;
      }
      .batch{
        color: #747474;
        font-size: 12px;
      }
    }
  }
  .part-right{
    color: #2E2E2E;
    font-size: 20px;
    display: flex;
    span{
      margin-right: 7px;
    }
    i{
      color: #F5F5F5;
    }
  }
}
.title-part{
  font-size: 14px;
  color: #747474;
  display: flex;
  align-items: center;
  margin-top: 15px;
  .blue-line{
    width: 4px;
    height: 14px;
    display: inline-block;
    margin-left: 12px;
    margin-right: 5px;
    border-radius: 2px;
    background-color: #2F9FF2;
  }
}
</style>
<style lang="scss">
.van-nav-bar .van-icon {
    color: #ffffff;
}
.van-nav-bar__title {
    color: #ffffff;
}
.van-nav-bar__text {
    color: #ffffff;
}
[class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
  height: 80px;
}
.van-switch__node {
  width: 20px !important;
  height: 20px !important;
  left: 2px;
}
#listLoading .van-loading{
    position: fixed !important;
    color: #c8c9cc !important;
    font-size: 0;
    vertical-align: middle;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    background: #333333;
    opacity: 0.8;
    border-radius: 10px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
#listLoading .van-loading__spinner {
      color: rgb(255, 255, 255);
      width: 40px !important;
      height: 40px !important;
      margin: 20px !important;
  }
</style>
